// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-competition-js": () => import("./../../../src/pages/case-competition.js" /* webpackChunkName: "component---src-pages-case-competition-js" */),
  "component---src-pages-club-programming-js": () => import("./../../../src/pages/club-programming.js" /* webpackChunkName: "component---src-pages-club-programming-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-vvc-js": () => import("./../../../src/pages/vvc.js" /* webpackChunkName: "component---src-pages-vvc-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-whats-happening-js": () => import("./../../../src/pages/whats-happening.js" /* webpackChunkName: "component---src-pages-whats-happening-js" */)
}

